import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewChild
} from '@angular/core'
import { WithGlobals } from '../../../classes/with-globals'
import { ModalComponent } from '../../layout/modal/modal.component'
import { Observable } from 'rxjs'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { hideDelay } from '../../../functions/hide-delay'
import { NgIf } from '@angular/common'
import { ButtonComponent } from '../../buttons/button/button.component'
import { Color } from '@awork/_shared/types/color'
import { Size } from '@awork/_shared/types/size'
import { InfoBoxComponent } from '../../ui-help/info-box/info-box.component'
import { ButtonColor, ButtonState } from '../../buttons/button/types'

@AutoUnsubscribe()
@Component({
  selector: 'aw-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  imports: [ModalComponent, NgIf, ButtonComponent, InfoBoxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class ConfirmationModalComponent extends WithGlobals implements AfterViewInit, OnDestroy {
  @Input({ required: true }) title: string
  @Input() subtitle?: string
  @Input() highlightedText?: string

  @Input({ required: true }) actionText: string
  @Input() cancelText?: string = q.translations.common.cancel
  @Input() loadingText?: string = ''
  @Input() actionColor?: ButtonColor = Color.Blue

  @Input() loadingObservable?: Observable<any>
  @Input() delayHide? = false
  @Input() zIndex?: number
  @Input() autoShow = true

  @Output() confirm: EventEmitter<void> = new EventEmitter<void>()
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>()
  @Output() hiding: EventEmitter<void> = new EventEmitter<void>()
  @Output() actionEmitted: EventEmitter<'confirm' | 'cancel'> = new EventEmitter<'confirm' | 'cancel'>()

  @ViewChild('modal') modal: ModalComponent

  protected buttonState = signal<ButtonState>('default')
  protected size = Size

  constructor(private cdr: ChangeDetectorRef) {
    super()
  }

  ngAfterViewInit(): void {
    if (this.autoShow) {
      setTimeout(() => {
        this.show()
      })
    }
  }

  ngOnDestroy() {}

  /**
   * Show the delete project status modal
   */
  show(): void {
    if (this.modal && this.modal.show) {
      this.modal.zIndex = this.zIndex
      this.modal.show()

      this.cdr.markForCheck()
    }
  }

  /**
   * Cancel the confirmation
   */
  onCancel(): void {
    this.cancel.emit()
    this.actionEmitted.emit('cancel')

    this.hide()
  }

  /**
   * Hide the delete project status modal
   */
  hide(): void {
    this.modal.hide()

    setTimeout(() => {
      this.hiding.emit()
      this.cdr.markForCheck()
    }, 0)

    this.buttonState.set('default')
  }

  /**
   * Emits event for confirmation
   */
  onConfirm(): void {
    this.confirm.next()
    this.actionEmitted.emit('confirm')

    if (!this.loadingObservable) {
      if (this.delayHide) {
        this.buttonState.set('success')
        hideDelay(() => this.hide())
      } else {
        this.hide()
      }
    } else {
      this.buttonState.set('loading')

      this.loadingObservable.subscribe(loading => {
        if (loading) {
          this.buttonState.set('success')
          hideDelay(() => this.hide())
        } else {
          this.buttonState.set('default')
        }
      })
    }
  }
}
