<article class="wrapper" [ngClass]="[size, view, showSmallDescription ? 'show-small-description' : '']">
  <!-- File Preview -->
  <div
    class="preview-wrapper aw-2-pos--relative aw-2-b--radius-m"
    [class.aw-2-sh--xs]="size === sizes.s"
    [class.aw-2-sh--s]="!showSmallDescription && size !== sizes.s"
  >
    <!-- Loader -->
    <aw-dots-loader *ngIf="isLoading" class="aw-2-pos--center-all" [color]="colors.Fog" />

    <!-- Image thumbnail -->
    <img
      *ngIf="showImageThumbnail && view === 'image' && file?.isPreviewSupported()"
      [hidden]="isLoading"
      [src]="previewUrl"
      (load)="imageLoaded()"
      (error)="imageLoadingError()"
    />

    <!-- Icon wrapper -->
    <div *ngIf="['icon', 'externalFile'].includes(view)" class="icon-wrapper">
      <aw-file-icon
        [extension]="file.fileExtension"
        [externalProvider]="file.externalProvider"
        [size]="size === sizes.s ? sizes.l : sizes.xl"
      />
    </div>

    <!-- Actions -->
    <div class="actions-wrapper aw-2-flx aw-2-pos--absolute">
      <!-- Show External File -->
      <a *ngIf="view === 'externalFile'" target="_blank" [href]="file.externalFileUrl">
        <aw-fab-button
          [icon]="'open_in_new'"
          [size]="size === sizes.s ? sizes.s : sizes.m"
          [awTooltip]="q.translations.common.open"
          [actionTooltip]="true"
          [suppressClick]="true"
          [showDelay]="200"
        />
      </a>

      <!-- Download -->
      <a *ngIf="size !== sizes.s && view !== 'externalFile'" #downloadLink class="clean-link" [href]="file.downloadUrl">
        <aw-fab-button
          [icon]="'file_download'"
          [size]="size === sizes.s ? sizes.s : sizes.m"
          [awTooltip]="q.translations.common.download"
          [actionTooltip]="true"
          [suppressClick]="true"
          [showDelay]="200"
        />
      </a>

      <!-- Open Carousel -->
      <aw-fab-button
        *ngIf="!showTinyDescription && supportsPreview"
        [icon]="'search'"
        [size]="size === sizes.s ? sizes.s : sizes.m"
        [awTooltip]="q.translations.common.preview"
        [actionTooltip]="true"
        [showDelay]="200"
        (click)="onView()"
      />

      <!-- Delete -->
      <aw-fab-button
        *ngIf="size !== sizes.s && canEdit"
        class="aw-2-btn--clear"
        [icon]="'delete'"
        [size]="size === sizes.s ? sizes.s : sizes.m"
        [awTooltip]="q.translations.common.delete"
        [actionTooltip]="true"
        [showDelay]="200"
        [color]="colors.Red"
        (click)="onDelete()"
      />
    </div>
  </div>

  <!-- File Info -->
  <div
    *ngIf="showDescription"
    class="info-wrapper aw-2-flx"
    [ngClass]="[sizes.m, sizes.l].includes(size) ? 'aw-2-pt--m' : ''"
  >
    <aw-user-avatar
      *ngIf="uploader?.id && [sizes.m, sizes.l].includes(size)"
      [user]="uploader"
      [size]="sizes.m"
      [showText]="false"
      [awTooltip]="uploader?.name"
    />

    <!-- Info Text -->
    <div
      *ngIf="[sizes.m, sizes.l].includes(size) || (size === sizes.s && showSmallDescription)"
      class="info-wrapper__text aw-2-flx--flex-1"
    >
      <!-- File Name -->
      <p class="info-wrapper__text__filename aw-2-txt--truncate aw-2-txt--night aw-2-txt--bold" [title]="file.name">
        {{ file.name }}
      </p>

      <aw-date-time-label
        *ngIf="size === sizes.l || (size === sizes.m && !file.task?.taskStatus)"
        class="date aw-2-txt--body-tiny aw-2-txt--bold"
        [class.aw-2-ml--auto]="sizes.m"
        [date]="file.updatedOn"
      />

      <aw-task-avatar
        *ngIf="file.task?.taskStatus && size !== sizes.s"
        class="info-wrapper__text__task-avatar"
        [task]="file.task"
        [size]="sizes.xs"
        [link]="true"
        [hideProject]="true"
      />

      <!-- File Size -->
      <p
        *ngIf="size === sizes.s && showSmallDescription"
        class="info-wrapper__text__file-size aw-2-txt--steel aw-2-txt--bold"
      >
        {{ file.size | fileSize }}
      </p>
    </div>

    <p
      *ngIf="showTinyDescription && !showSmallDescription && size === sizes.s"
      class="info-wrapper__tiny-description aw-2-d--block aw-2-txt--truncate aw-2-txt--bold aw-2-pt--xs"
    >
      {{ file.name }}
    </p>
  </div>
</article>
